*{
  box-sizing: border-box;
}

.home-headline {
  height: 200px;
  background: #41f3be;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about {
  margin:  auto;
  width: 90%;
  padding: 10px;
  background: #41f3be;
}

.contact-form {
  background-color: #41f3be;
  margin: 100px auto;
  width: 90%;
  box-shadow: 1px 1px 4px 1px lightgrey;
}

.contact-form form {
  width: 100%;
  padding: 10px;
}

.contact-form label {
  display: block;
  letter-spacing: 0.1rem;
  font-style: italic;
  margin-bottom: 3px;
}

.contact-form input, .contact-form textarea {
  width: 100%;
  margin: auto;
  padding: 10px;
  border: none;
  box-shadow: 1px 1px 4px 1px rgb(58, 57, 57);
}

.contact-form input:focus, .contact-form textarea:focus {
  box-shadow: 1px 1px 4px 1px rgb(49, 170, 240);
}

.form-item {
  margin: 10px 0;
}